<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="手机/真实姓名"
                        label-width="130px">
            <el-input clearable
                      style="width:100%"
                      v-model="form.keyword"
                      placeholder="手机号/真实姓名"
                      @keyup.enter.native="search"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="用户类型">
            <el-select v-model="form.user_type_id"
                       style="width:100%"
                       filterable
                       clearable
                       placeholder="请学员类型">
              <el-option v-for="item in stuTypeList"
                         :key="item.user_type_id"
                         :label="item.user_type_name"
                         :value="item.user_type_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="所在学校"
                        prop="school_id">
            <el-select v-model="form.school_id"
                       filterable
                       clearable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="改卷类型"
                        prop="correct_type">
            <el-select v-model="form.correct_type"
                       filterable
                       clearable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in eduPaperList"
                         :key="item.key"
                         :label="item.label"
                         :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开通时间"
                        label-width="130px">
            <el-date-picker v-model="form.create_time"
                            @focus="$forbid"
                            style="width:100%"
                            type="datetime"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="开通时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            @focus="$forbid"
                            style="width:100%"
                            type="datetime"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="截止时间" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-button type="primary"
                     @click="search">确定</el-button>
          <el-button type="primary"
                     @click="addStudent">新增学员</el-button>
          <el-button type="primary"
                     @click="importExcelFunc">导入</el-button>
        </el-col>
      </el-row>
    </el-form>

    <Tables ref="TablesRef"
            :tableData="tableData" />

    <EditForm ref="EditFormRef" />
    <timeForm ref="timeFormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <importExcel ref="importExcelRef" />
  </div>
</template>

<script>
import EditForm from './components/editForm.vue'
import timeForm from './components/subject_time.vue'
import importExcel from './components/importExcel.vue'
import Tables from '@/views/components/table/tables.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      form: {
        user_type_id: '',
        school_id: '',
        keyword: '',
        create_time: '',
        end_time: '',
        correct_type: '',
      },
      pageName: 'studentAdmin',
      tableColumns: [
        { prop: 'id', align: 'center', label: '编号', width: '80px', showToopic: false },
        { prop: 'account', align: 'center', label: '登录账号', width: '130', showToopic: false },
        { prop: 'student_no', align: 'center', label: '学号/手机号', width: '130', showToopic: false },
        { prop: 'nickname', align: 'center', label: '昵称', width: '120', showToopic: false },
        { prop: 'truename', align: 'center', label: '学生姓名', width: '', showToopic: false },
        { prop: 'school_name', align: 'center', label: '所在学校', width: '', showToopic: false },
        { prop: 'grade_name', align: 'center', label: '年级', width: '', showToopic: false },
        { prop: 'user_type_name', align: 'center', label: '用户类型', width: '', showToopic: false },
        { prop: 'correct_type', align: 'center', label: '改卷类型', width: '', showToopic: false },
        { prop: 'create_time', align: 'center', label: '开通时间', width: '', showToopic: false },
        { prop: 'end_time', align: 'center', label: '到期时间', width: '', showToopic: false },
        { prop: 'paper_district_name', align: 'center', label: '试卷地区', width: '', showToopic: false },
        { prop: 'graduate_year', align: 'center', label: '毕业年份', width: '', showToopic: false },
        { prop: 'class_name', align: 'center', label: '班', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '150', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'showForm' },
            { content: '删除', type: 'del', event: 'delRow' },
            { content: '重置密码', type: 'reset', event: 'resetPwd' },
          ]
        },
      ],
      stuTypeList: [],
      schoolList: [],
      eduPaperList: [
        //  '1': '平台改卷', '2': '学生自评', '3': '本校改卷', '4': '本校改卷(释放平台)',
        { key: 1, label: '平台改卷' },
        { key: 2, label: '学生自评' },
        { key: 3, label: '本校改卷' },
        { key: 4, label: '本校改卷(释放平台)' },
      ]
    }
  },
  components: {
    EditForm, timeForm, importExcel, Tables
  },
  mounted () {
    this.setTablesColums()
    this.getList()
  },
  methods: {
    getList () {
      // 获取学员类型
      this.$http({
        url: '/api/v2/public/user_type',
        method: 'get'
      }).then(res => {
        this.stuTypeList = res.data
      })

      // 获取学校
      this.$http({
        url: '/api/v2/public/enabled_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/student/lst',
        method: 'get',
        params: form
      }).then(res => {
        // let typeObj = {
        //   '1': '平台改卷', '2': '学生自评', '3': '本校改卷', '4': '本校改卷(释放平台)',
        // }
        // for (let index = 0; index < res.data.list.length; index++) {
        //   let row = res.data.list[index]
        //   row.gaijuan = typeObj[row.correct_type]
        // }
        res.data.list.map(item => {
          item.student_no = item.student_no ? item.student_no : item.verifiedMobile
          return item
        })
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },

    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/student/del',
          method: 'post',
          data: {
            id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addStudent () {
      this.$router.push('/studentAdmin/addStudent')
    },
    showForm (row) {
      this.$router.push('/studentAdmin/addStudent?id=' + row.id)
    },
    resetPwd (row) {
      this.$confirm("此操作将重置密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/student/reset_pwd',
          method: 'post',
          data: {
            id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    importExcelFunc () {
      let form = {
        user_type_id: '',
        paper_district_id: '',
        end_time: '',
        isCover: 0,
        bk_end_time: ''
      }
      this.$refs.importExcelRef.form = form
      this.$refs.importExcelRef.getList()
      this.$refs.importExcelRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
