<template>
  <div>
    <el-dialog title="修改科目时间"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1000px"
               :before-close="handleClose">
      <div v-for="item in timers"
           :key="item.grade_id">
        <div class="title">
          {{item.grade_name}}
        </div>
        <div class="time_warp">
          <div v-for="it1 in item.subjects"
               :key="it1.grade_id"
               class="subject">
            <div>
              {{it1.subject_name}}:
            </div>
            <!-- {{it1.end_time}} -->
            <el-date-picker v-model="it1.end_time"
                            type="datetime"
                            readonly
                            default-time="23:59:59"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div v-if="timers.length == 0"
           style="text-align:center">
        该用户暂无科目时间可修改
      </div>
      <span slot="footer"
            v-if="timers.length != 0"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      timers: [],
      form: {}
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      let form = JSON.parse(JSON.stringify(this.form))
      let grade_subject_map = JSON.parse(JSON.stringify(this.timers))
      grade_subject_map.map(item => {
        delete item.grade_name
        var subjects = item.subjects.map(it1 => {
          delete it1.subject_name
          return it1
        })
        item.subjects = subjects
        return item
      })
      form.grade_subject_map = grade_subject_map
      this.$http({
        url: '/api/v2/student/create',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        this.$parent.refresh()
        this.handleClose()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.time_warp {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 33%;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.subject {
  display: flex;
  margin: 5px 0;
  div {
    &:nth-child(1) {
      width: 90px;
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }
}
</style>