<template>
  <div>
    <el-dialog title="修改学员信息"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="90px">
        <el-row :gutter="10"
                style="border-bottom:1px solid #F2F2F2">
          <el-col :span="12">
            <el-form-item label="手机号"
                          prop="verifiedMobile">
              <el-input placeholder="请输入手机号"
                        v-model="form.verifiedMobile"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名"
                          prop="truename">
              <el-input placeholder="请输入真实姓名"
                        v-model="form.truename"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="别名">
              <el-input placeholder="请输入别名"
                        v-model="form.nickname"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="毕业年份">
              <el-date-picker v-model="form.graduate_year"
                              disabled
                              value-format="yyyy"
                              type="year"
                              style="width:100%"
                              placeholder="请选择毕业年份">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所在学校"
                          prop="edu_school_id">
              <el-select v-model="form.edu_school_id"
                         filterable
                         @change="getClass"
                         disabled
                         style="width:100%"
                         placeholder="请选择所在学校">
                <el-option v-for="item in schoolList"
                           :key="item.school_id"
                           :label="item.school_name"
                           :value="item.school_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="当前年级"
                          prop="edu_grade_id">
              <el-select v-model="form.edu_grade_id"
                         style="width:100%"
                         disabled
                         @change="getClass"
                         placeholder="请选择年级">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="班级"
                          prop="edu_class_id">
              <el-select v-model="form.edu_class_id"
                         style="width:100%"
                         filterable
                         placeholder="请选择班级">
                <el-option v-for="item in classList"
                           :key="item.id"
                           :label="item.class_name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="改卷类型">
              <el-radio-group v-model="form.correct_type">
                <el-radio :label="2">学生自评</el-radio>
                <el-radio :label="1">平台改卷</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="本校改卷"
                          class="hidden_radio">
              <el-radio :label="1"
                        v-model="form.check_paper"
                        @click.prevent.native="checkRadio"></el-radio>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="学员类型">
              <el-select v-model="form.user_type_id"
                         style="width:100%"
                         filterable
                         clearable
                         placeholder="请学员类型">
                <el-option v-for="item in stuTypeList"
                           :key="item.user_type_id"
                           :label="item.user_type_name"
                           :value="item.user_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="截止时间"
                          style="margin-bottom:14px">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              default-time="23:59:59"
                              placeholder="选择日期">
              </el-date-picker>
              <div class="toopic"><span>
                  注:各种非学员(测试账号、供应商账号等)默认为一直使用</span></div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="试卷地区">
              <el-select v-model="form.paper_district"
                         style="width:100%"
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in dirList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="年级科目:">学员科目需要在这里指定，不指定科目不能添加！科目的修改任然保留在这“学员科目管理”菜单下</el-form-item>
          </el-col>
        </el-row>

        <el-tabs v-model="activeName">
          <el-tab-pane :label="grade.grade_name"
                       v-for="grade in gradeList"
                       :key="grade.grade_name"
                       :name="grade.grade_name">
            <el-checkbox-group v-model="grade.subjects"
                               :disabled="form.is_retail != 1">
              <el-checkbox :label="subject.subject_id"
                           v-for="subject in grade.gradeList"
                           :key="subject.subject_id">{{subject.subject_name}}</el-checkbox>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>

      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        truename: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        verifiedMobile: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        edu_grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_school_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_class_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      value: '',
      options: [],
      classList: [],
      activeName: '初三',
      checkList: [],
      subjectList: [],
      gradeList: [],
      schoolList: [],
      stuTypeList: [],
      dirList: [],
      tallOrfrist: [
        { value: 1, label: '高中' },
        { value: 0, label: '初中' },
      ],
      timeArr: []
    }
  },
  // watch: {
  //   subjectList: {
  //     handler (val) {
  //       console.log('val', val)
  //     }
  //   },
  //   deep: true
  // },
  methods: {
    getClass () {
      this.form.edu_class_id = ''
      if (this.form.edu_school_id == '' || this.form.edu_grade_id == '') return
      this.getClassList()
    },
    getClassList () {
      this.$http({
        url: '/api/v2/public/class_sg',
        method: 'get',
        params: {
          school_id: this.form.edu_school_id,
          grade_id: this.form.edu_grade_id
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.grade_subject_map = []
        this.gradeList.forEach(item => {
          var arr = []
          item.subjects.forEach(it1 => {
            let obj1 = {
              id: it1,
              end_time: this.form.end_time
            }
            arr.push(obj1)
          })
          let obj = {
            grade_id: item.grade_id,
            subjects: arr,
          }
          form.grade_subject_map.push(obj)
        })
        this.$http({
          url: '/api/v2/student/create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
    //点击修改时，将用户所选中的年级科目传递到这里进行遍历
    getList (arr = []) {
      // 获取学校
      this.$http({
        url: '/api/v2/public/enabled_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })

      // 获取学员类型
      this.$http({
        url: '/api/v2/public/user_type',
        method: 'get'
      }).then(res => {
        this.stuTypeList = res.data
      })

      // 获取试卷地区
      this.$http({
        url: '/api/v2/public/paper_district',
        method: 'get'
      }).then(res => {
        this.dirList = res.data.list
      })
      this.$http({
        url: '/api/v2/public/subject_new',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.map(item => {
          item.gradeList = [...Object.values(JSON.parse(JSON.stringify(item.subjects)))]
          if (arr.length == 0) {
            item.subjects = item.gradeList.map(item => item.subject_id)
          } else {
            item.subjects = []
            arr.forEach(row => {
              if (item.grade_id == row.grade_id) {
                item.subjects.push(row.subject_id)
              }
            })
          }
          return item
        })
      })

      // 获取科目
      this.$http({
        url: '/api/v2/public/paper_subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    checkRadio () {
      if (this.form.check_paper == 0) {
        this.form.check_paper = 1
        return
      }
      this.form.check_paper = 0
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
.hidden_radio {
  ::v-deep .el-radio__label {
    display: none;
  }
}

// ::v-deep .el-checkbox {
//   width: 100px;
//   margin-right: 10px;
// }
::v-deep .el-checkbox {
  width: 100px;
  margin-right: 25px;
  &:nth-child(10) {
    width: 80px;
  }
}

::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #409eff;
}
</style>